import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For the first time since 2019, the Geological Exploration Society of Great Britain (formerly PESGB) held its 'Africa' meeting in London, 2023 September20-21.
The venue was The Oval cricket ground, south of the Thames. On the first day Colin Reeves gave a presentation entitlted 'The Origin of Basins and Minor
Fragments around Southern Africa in a Unified Plate-Tectonic Model' `}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/oval-abstract.pdf"
      }}>{`(2-page abstract with figures)`}</a>{`. `}</p>
    <p>{`Enlarging on the Vienna EGU presentation (2023 April 27 - see earlier news item), the talk featured two new animations, the first of a whole-Gondwana dispersion
model centred on the Africa-Antartica Corridor (AAC) and a second of the paleo-positions of the basins and microfragments off South Africa and Patagonia in the
context of the early growth of the Weddell Sea. In the event, the animations in the presentation failed. As a result, the thrust of the talk was missed. To make
any kind of animation visually credible while obeying simple, logical rules of plate tectonics as well as acknowledging sound geological interpretations has
proved virtually impossible until now. The model illustrated in the second animation was asserted to be worthy of further investigation.`}</p>
    <p>{`For those interested, the two animations are now availbale for viewing here:`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/gondwana-aac.gif"
      }}>{`Gondwana dispersal centred on the AAC`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/bouvet-tj-e-cret.gif"
      }}>{`Early Cretaceous Bouvet triple junction`}</a></p>
    <p>{`The latest version of an earlier animation showing the proposed early movements of the micro-fragments around southern Africa is to be seen `}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/cr23baag-BTJ-2.gif"
      }}>{`here`}</a>{`. The animated GIF is almost 30 MB so may take a few seconds to load. Attention is also drawn to the poster
presented at The Oval meeting by co-author Duncan Macgregor on his `}<a parentName="p" {...{
        "href": "https://www.africageologicalatlas.com/"
      }}>{`'Africa Geological Atlas'`}</a>{`.`}</p>
    <p>{`As always, enquiries are warmly welcomed, as are opportunities to spread these results further.  The full Powerpoint presentation from The Oval is available on
request. Please get in touch (`}<a parentName="p" {...{
        "href": "mailto:reeves.earth@planet.nl"
      }}>{`reeves.earth@planet.nl`}</a>{`)`}</p>
    <p><em parentName="p">{`2023 October 02`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      